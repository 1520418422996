import { HostConst, MethodConst, apiData } from 'services/base'
import { ContactType } from 'types/contactTypes'

export const readContactService = (id: string) => {
  return apiData(MethodConst.GET, `contacts/${id}`, HostConst.CANDIDATES)
}

export const updateContactService = (
  candidateId: string,
  id: string,
  contact: ContactType
) => {
  return apiData(
    MethodConst.PATCH,
    `candidates/${candidateId}/contacts/${id}`,
    HostConst.CANDIDATES,
    {},
    contact
  )
}

export const listContactService = (candidateId: string) => {
  return apiData(
    MethodConst.GET,
    `candidates/${candidateId}/contacts`,
    HostConst.CANDIDATES
  )
}
