import {
  listContactService,
  updateContactService,
} from 'services/contactServices'
import { successHandlerListStub, successHandlerUpdateStub } from 'mocks/base'
import { contactIdMock, contactMock, contactsMock } from 'mocks/candidates/contactMock'
import { candidateIdMock } from "mocks/candidates/candidateMock";

// Services
const { url: listUrl } = listContactService(contactIdMock)
const { url: updateUrl } = updateContactService(
  candidateIdMock,
  contactIdMock,
  contactMock
)

// Stubs
const listStub = successHandlerListStub(listUrl, contactsMock)
const updateStub = successHandlerUpdateStub(updateUrl, contactMock)

export default [listStub, updateStub]
