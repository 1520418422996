import React, { FC } from 'react'
import { TitleWrapperStyled } from 'pages/LoginPage/styles'
import { Font, Button } from 'electivegroup-component-library'

type Props = {
  label: string
  route: string
}
const Todo: FC<Props> = ({ label, route }) => {
  return (
    <TitleWrapperStyled>
      <Font variant="h5" component="h1" gutterBottom align="center">
        {label}
        <Button label=">" theme="secondary" href={`/account/${route}`} />
      </Font>
    </TitleWrapperStyled>
  )
}

export default Todo
