import styled from 'styled-components'

export const OptionsWrapperStyled = styled.div`
  width: 100%;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr auto;
  align-items: center;
`

export const TitleWrapperStyled = styled.div`
  display: grid;
  justify-content: center;
`