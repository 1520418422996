import { useRef, useEffect, lazy } from 'react'

export const useEffectAfterMount = (callback: any, dependencies: any) => {
  const justMounted = useRef(true)
  useEffect(() => {
    if (!justMounted.current) {
      return callback()
    }
    justMounted.current = false
  }, dependencies)
}

export const capitalise = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}


export type QueryOptionsType = {
  limit?: number
  offset?: number
  detailed?: boolean
  count?: boolean
  countOnly?: boolean
  filters?: Array<string>
  sorts?: Array<string>
}

export const createFilterString = (options: QueryOptionsType) => {
  const { limit, offset, detailed, count, countOnly, filters, sorts } = options

  const filterQuery = []

  limit && filterQuery.push(`limit=${limit}`)
  offset && filterQuery.push(`offset=${offset}`)
  detailed && filterQuery.push(`detailed=true`)
  count && filterQuery.push(`count=true`)
  countOnly && filterQuery.push(`countOnly=true`)

  filters &&
  Object.values(filters).forEach((filter: any) =>
    filterQuery.push(`filters[]=${encodeURIComponent(filter)}`)
  )

  sorts &&
  Object.values(sorts).forEach((sort: any) =>
    filterQuery.push(`sorts[]=${encodeURIComponent(sort)}`)
  )

  return filterQuery.join('&')
}

export const lazyWithPreload = (factory: any) => {
  const Component: any = lazy(factory)
  Component.preload = factory
  return Component
}
