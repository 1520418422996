import { userMock } from 'mocks/users/userMock'
import { candidateIdMock } from 'mocks/candidates/candidateMock'

export const authMock: any = {
  username: userMock.username,
  candidateId: candidateIdMock,
}

export const tokenMock = {
  token:
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MTE3NDk3MjEsInVzZXJuYW1lIjoidXNlciIsImV4cCI6OTYxMTc2ODc0NiwiY2FuZGlkYXRlSWQiOiJrbW85RXQxSXhxQ2kiLCJuYW1lc3BhY2UiOiJhY21lIiwianRpIjoiMDRhNzBiOTUtYWRjOS00OWJlLTlmNmMtNGIwYWIyZDAxNzE1In0.EfTi0EiZmihnSU6wfESGDBsQN060Jt04XuZU-WPxAG8',
}

export const authorisationMock: any = {
  username: userMock.username,
  candidateId: candidateIdMock,
  token: tokenMock.token,
}

export const loginMock: any = {
  email: 'user',
  password: 'user',
  rememberMe: false,
}

export const authPermissionsMock = [
  'CANDIDATE_DELETE',
  'EMAIL_VIEW',
  'EMAIL_CREATE',
  'EMAIL_EDIT',
  'EMAIL_DELETE',
  'TRANSCRIPT_VIEW',
  'CANDIDATE_VIEW',
  'CANDIDATE_CREATE',
  'CANDIDATE_EDIT',
  'LABEL_VIEW',
  'CANDIDATE_LABEL_VIEW',
  'INTEGRATION_DELETE',
  'SITE_SETTINGS_VIEW',
  'INTEGRATION_VIEW',
  'IMPORT_VIEW',
  'EXPORT_VIEW',
  'EXPORTRECORD_VIEW',
  'JOB_CREATE',
  'JOB_EDIT',
  'JOB_ASSESSMENT_CREATE',
  'JOB_ASSESSMENT_EDIT',
  'JOB_LABEL_EDIT',
  'JOB_LABEL_CREATE',
  'JOB_LABEL_DELETE',
  'JOBNOTIFICATIONCHANNEL_CREATE',
  'JOBNOTIFICATIONCHANNEL_EDIT',
  'JOBNOTIFICATIONCHANNEL_DELETE',
  'JOBNOTIFICATIONCHANNEL_UNDELETE',
  'SHORTLINK_CREATE',
  'SHORTLINK_EDIT',
  'SHORTLINK_DELETE',
  'JOBLINK_CREATE',
  'JOBLINK_EDIT',
  'JOBLINK_DELETE',
  'JOBLINK_UNDELETE',
  'INTEGRATION_CREATE',
  'INTEGRATION_EDIT',
  'EXPORT_CREATE',
  'SERVICE_ACCOUNT_VIEW',
  'TOKEN_VIEW',
  'ASSESSMENT_VIEW',
  'A_QUESTION_VIEW',
  'QUESTION_VIEW',
  'ASSESSMENT_LABEL_VIEW',
  'SITE_MANAGEMENT_VIEW',
  'VARIATION_VIEW',
  'ROLE_VIEW',
  'ROLE_CREATE',
  'ROLE_EDIT',
  'SERVICE_ACCOUNT_CREATE',
  'SERVICE_ACCOUNT_EDIT',
  'TOKEN_CREATE',
  'TOKEN_EDIT',
  'JOB_VIEW',
  'CLIENT_VIEW',
  'JOB_ASSESSMENT_VIEW',
  'JOB_LABEL_VIEW',
  'CHANNEL_VIEW',
  'JOBNOTIFICATIONCHANNEL_VIEW',
  'SHORTLINK_VIEW',
  'JOBLINK_VIEW',
  'ORGANISATIONPROFILE_VIEW',
  'UOR_VIEW',
  'UOR_CREATE',
  'UOR_EDIT',
  'SITE_HELP_EDIT',
  'JOB_DELETE',
  'JOB_ASSESSMENT_DELETE',
  'USER_DELETE',
  'UOR_DELETE',
  'USER_VIEW',
  'VARIATION_CREATE',
  'VARIATION_EDIT',
  'CHANNELTYPE_VIEW',
  'PHONENUMBER_VIEW',
  'CHANNELNUMBER_VIEW',
  'SITE_HELP_MASTER',
  'SITE_HELP_OWNER',
  'SITE_SETTINGS_UNDELETE',
  'SITE_SETTINGS_OPERATOR',
  'SITE_SETTINGS_MASTER',
  'ASSESSMENT_CREATE',
  'ASSESSMENT_EDIT',
  'ASSESSMENT_DELETE',
  'ASSESSMENT_MASTER',
  'ASSESSMENT_OWNER',
  'INVITATION_VIEW',
  'INVITATION_CREATE',
  'INVITATION_OWNER',
  'ANSWERRECORDING_VIEW',
  'ANSWERRECORDING_EDIT',
  'ANSWERRECORDING_UNDELETE',
  'ANSWERRECORDING_OPERATOR',
  'ANSWERRECORDING_MASTER',
  'ANSWERRECORDING_OWNER',
  'TRANSCRIPT_CREATE',
  'TRANSCRIPT_EDIT',
  'TRANSCRIPT_DELETE',
  'TRANSCRIPT_UNDELETE',
  'TRANSCRIPT_OPERATOR',
  'TRANSCRIPT_MASTER',
  'TRANSCRIPT_OWNER',
  'A_QUESTION_CREATE',
  'A_QUESTION_EDIT',
  'A_QUESTION_DELETE',
  'A_QUESTION_UNDELETE',
  'A_QUESTION_OPERATOR',
  'A_QUESTION_MASTER',
  'A_QUESTION_OWNER',
  'CLIENT_LABEL_VIEW',
  'IMPORT_DELETE',
  'VARIATION_DELETE',
  'USER_CREATE',
  'USER_EDIT',
  'INVITATION_EDIT',
  'ENGAGEMENT_EDIT',
  'JOB_REMINDER_EDIT',
  'JOB_REMINDER_CREATE',
  'ENGAGEMENT_LABEL_CREATE',
  'ENGAGEMENT_LABEL_EDIT',
  'ENGAGEMENT_LABEL_DELETE',
  'INVITATION_LABEL_CREATE',
  'INVITATION_LABEL_EDIT',
  'INVITATION_LABEL_DELETE',
  'ENGAGEMENT_RATING_CREATE',
  'ENGAGEMENT_RATING_EDIT',
  'ROLE_DELETE',
  'SERVICE_ACCOUNT_DELETE',
  'TOKEN_DELETE',
  'INTEGRATIONTYPE_VIEW',
  'CLIENT_CREATE',
  'CLIENT_EDIT',
  'CLIENT_DELETE',
  'ENGAGEMENT_VIEW',
  'ENGAGEMENT_CREATE',
  'ENGAGEMENT_DELETE',
  'JOB_REMINDER_VIEW',
  'IMPORT_CREATE',
  'IMPORT_EDIT',
  'EXPORTRECORD_CREATE',
  'CANDIDATE_LABEL_CREATE',
  'CANDIDATE_LABEL_EDIT',
  'CANDIDATE_LABEL_DELETE',
  'PERMISSIONGROUP_VIEW',
  'CLIENT_LABEL_CREATE',
  'CLIENT_LABEL_EDIT',
  'CLIENT_LABEL_DELETE',
  'QUESTION_CREATE',
  'QUESTION_EDIT',
  'ASSESSMENT_LABEL_CREATE',
  'ASSESSMENT_LABEL_EDIT',
  'ASSESSMENT_LABEL_DELETE',
  'QUESTION_DELETE',
  'ORGANISATIONPROFILE_EDIT',
  'SITE_SETTINGS_EDIT',
  'ORGANISATION_VIEW',
  'ENGAGEMENT_LABEL_VIEW',
  'INVITATION_LABEL_VIEW',
  'ENGAGEMENT_RATING_VIEW',
  'SITE_HELP_VIEW',
  'ANSWERRECORDING_CREATE',
  'LABEL_CREATE',
  'LABEL_EDIT',
  'LABEL_DELETE',
  'CLIENT_UNDELETE',
  'USER_UNDELETE',
  'USER_OPERATOR',
  'USER_MASTER',
  'USER_OWNER',
  'ORGANISATIONPROFILE_CREATE',
  'ORGANISATIONPROFILE_DELETE',
  'ORGANISATIONPROFILE_UNDELETE',
  'ORGANISATIONPROFILE_OPERATOR',
  'ORGANISATIONPROFILE_MASTER',
  'ORGANISATIONPROFILE_OWNER',
  'PERMISSION_VIEW',
  'PERMISSION_CREATE',
  'PERMISSION_EDIT',
  'PERMISSION_DELETE',
  'PERMISSION_UNDELETE',
  'PERMISSION_OPERATOR',
  'PERMISSION_MASTER',
  'PERMISSION_OWNER',
  'SUBJECTPERMISSION_VIEW',
  'SUBJECTPERMISSION_CREATE',
  'SUBJECTPERMISSION_EDIT',
  'SUBJECTPERMISSION_DELETE',
  'SUBJECTPERMISSION_UNDELETE',
  'SUBJECTPERMISSION_OPERATOR',
  'SUBJECTPERMISSION_MASTER',
  'SUBJECTPERMISSION_OWNER',
  'SUBJECT_VIEW',
  'SUBJECT_CREATE',
  'SUBJECT_EDIT',
  'SUBJECT_DELETE',
  'SUBJECT_UNDELETE',
  'SUBJECT_OPERATOR',
  'SUBJECT_MASTER',
  'SUBJECT_OWNER',
  'PERMISSIONGROUP_CREATE',
  'PERMISSIONGROUP_EDIT',
  'PERMISSIONGROUP_DELETE',
  'PERMISSIONGROUP_UNDELETE',
  'PERMISSIONGROUP_OPERATOR',
  'PERMISSIONGROUP_MASTER',
  'PERMISSIONGROUP_OWNER',
  'ROLE_UNDELETE',
  'ROLE_OPERATOR',
  'ROLE_MASTER',
  'ROLE_OWNER',
  'EMAIL_UNDELETE',
  'EMAIL_OPERATOR',
  'EMAIL_MASTER',
  'EMAIL_OWNER',
  'ORGANISATION_CREATE',
  'ORGANISATION_EDIT',
  'ORGANISATION_DELETE',
  'ORGANISATION_UNDELETE',
  'ORGANISATION_OPERATOR',
  'ORGANISATION_MASTER',
  'ORGANISATION_OWNER',
  'SESSION_VIEW',
  'SESSION_CREATE',
  'SESSION_EDIT',
  'SESSION_DELETE',
  'SESSION_UNDELETE',
  'SESSION_OPERATOR',
  'SESSION_MASTER',
  'SESSION_OWNER',
  'UOR_UNDELETE',
  'UOR_OPERATOR',
  'UOR_MASTER',
  'UOR_OWNER',
  'SITE_HELP_CREATE',
  'SITE_HELP_DELETE',
  'SITE_HELP_UNDELETE',
  'SITE_HELP_OPERATOR',
  'SITE_SETTINGS_CREATE',
  'SITE_SETTINGS_DELETE',
  'SITE_SETTINGS_OWNER',
  'ASSESSMENT_UNDELETE',
  'ASSESSMENT_OPERATOR',
  'INVITATION_DELETE',
  'INVITATION_UNDELETE',
  'INVITATION_OPERATOR',
  'INVITATION_MASTER',
  'ANSWERRECORDING_DELETE',
  'QUESTION_UNDELETE',
  'QUESTION_OPERATOR',
  'QUESTION_MASTER',
  'QUESTION_OWNER',
  'VARIATION_UNDELETE',
  'VARIATION_OPERATOR',
  'VARIATION_MASTER',
  'VARIATION_OWNER',
  'JOB_UNDELETE',
  'JOB_OPERATOR',
  'JOB_MASTER',
  'JOB_OWNER',
  'CLIENT_OPERATOR',
  'CLIENT_MASTER',
  'CLIENT_OWNER',
  'SITE_ADMIN_VIEW',
  'SITE_ADMIN_CREATE',
  'SITE_ADMIN_EDIT',
  'SITE_ADMIN_DELETE',
  'SITE_ADMIN_UNDELETE',
  'SITE_ADMIN_OPERATOR',
  'SITE_ADMIN_MASTER',
  'SITE_ADMIN_OWNER',
  'CANDIDATE_UNDELETE',
  'CANDIDATE_OPERATOR',
  'CANDIDATE_MASTER',
  'CANDIDATE_OWNER',
  'INTEGRATION_UNDELETE',
  'INTEGRATION_OPERATOR',
  'INTEGRATION_MASTER',
  'INTEGRATION_OWNER',
  'ENGAGEMENT_UNDELETE',
  'ENGAGEMENT_OPERATOR',
  'ENGAGEMENT_MASTER',
  'ENGAGEMENT_OWNER',
  'INTEGRATIONTYPE_CREATE',
  'INTEGRATIONTYPE_EDIT',
  'INTEGRATIONTYPE_DELETE',
  'INTEGRATIONTYPE_UNDELETE',
  'INTEGRATIONTYPE_OPERATOR',
  'INTEGRATIONTYPE_MASTER',
  'INTEGRATIONTYPE_OWNER',
  'IMPORT_UNDELETE',
  'IMPORT_OPERATOR',
  'IMPORT_MASTER',
  'IMPORT_OWNER',
  'JOB_ASSESSMENT_UNDELETE',
  'JOB_ASSESSMENT_OPERATOR',
  'JOB_ASSESSMENT_MASTER',
  'JOB_ASSESSMENT_OWNER',
  'JOB_REMINDER_DELETE',
  'JOB_REMINDER_UNDELETE',
  'JOB_REMINDER_OPERATOR',
  'JOB_REMINDER_MASTER',
  'JOB_REMINDER_OWNER',
  'SERVICE_ACCOUNT_UNDELETE',
  'SERVICE_ACCOUNT_OPERATOR',
  'SERVICE_ACCOUNT_MASTER',
  'SERVICE_ACCOUNT_OWNER',
  'TOKEN_UNDELETE',
  'TOKEN_OPERATOR',
  'TOKEN_MASTER',
  'TOKEN_OWNER',
  'LABEL_UNDELETE',
  'LABEL_OPERATOR',
  'LABEL_MASTER',
  'LABEL_OWNER',
  'ENGAGEMENT_LABEL_UNDELETE',
  'ENGAGEMENT_LABEL_OPERATOR',
  'ENGAGEMENT_LABEL_MASTER',
  'ENGAGEMENT_LABEL_OWNER',
  'CANDIDATE_LABEL_UNDELETE',
  'CANDIDATE_LABEL_OPERATOR',
  'CANDIDATE_LABEL_MASTER',
  'CANDIDATE_LABEL_OWNER',
  'INVITATION_LABEL_UNDELETE',
  'INVITATION_LABEL_OPERATOR',
  'INVITATION_LABEL_MASTER',
  'INVITATION_LABEL_OWNER',
  'ASSESSMENT_LABEL_UNDELETE',
  'ASSESSMENT_LABEL_OPERATOR',
  'ASSESSMENT_LABEL_MASTER',
  'ASSESSMENT_LABEL_OWNER',
  'CLIENT_LABEL_UNDELETE',
  'CLIENT_LABEL_OPERATOR',
  'CLIENT_LABEL_MASTER',
  'CLIENT_LABEL_OWNER',
  'JOB_LABEL_UNDELETE',
  'JOB_LABEL_OPERATOR',
  'JOB_LABEL_MASTER',
  'JOB_LABEL_OWNER',
  'CHANNEL_EDIT',
  'CHANNEL_CREATE',
  'CHANNEL_DELETE',
  'CHANNEL_UNDELETE',
  'CHANNEL_OPERATOR',
  'CHANNEL_MASTER',
  'CHANNEL_OWNER',
  'CHANNELTYPE_EDIT',
  'CHANNELTYPE_CREATE',
  'CHANNELTYPE_DELETE',
  'CHANNELTYPE_UNDELETE',
  'CHANNELTYPE_OPERATOR',
  'CHANNELTYPE_MASTER',
  'CHANNELTYPE_OWNER',
  'PHONENUMBER_EDIT',
  'PHONENUMBER_CREATE',
  'PHONENUMBER_DELETE',
  'PHONENUMBER_UNDELETE',
  'PHONENUMBER_OPERATOR',
  'PHONENUMBER_MASTER',
  'PHONENUMBER_OWNER',
  'CHANNELNUMBER_EDIT',
  'CHANNELNUMBER_CREATE',
  'CHANNELNUMBER_DELETE',
  'CHANNELNUMBER_UNDELETE',
  'CHANNELNUMBER_OPERATOR',
  'CHANNELNUMBER_MASTER',
  'CHANNELNUMBER_OWNER',
  'JOBNOTIFICATIONCHANNEL_OPERATOR',
  'JOBNOTIFICATIONCHANNEL_MASTER',
  'JOBNOTIFICATIONCHANNEL_OWNER',
  'SHORTLINK_UNDELETE',
  'SHORTLINK_OPERATOR',
  'SHORTLINK_MASTER',
  'SHORTLINK_OWNER',
  'JOBLINK_OWNER',
  'SITE_INBOX_VIEW',
  'INVITATIONENGAGEMENTREQUEST_VIEW',
  'INVITATIONENGAGEMENTREQUEST_CREATE',
  'CHANNELAUTHORISATION_VIEW',
]
