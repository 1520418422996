import { successHandlerListStub, successHandlerCreateStub } from 'mocks/base'
import {
  createAuthService,
  exchangeAuthService,
  listAuthPermissionsService,
  refreshAuthService,
} from 'services/authServices'
import {
  authMock,
  authPermissionsMock,
  loginMock,
  tokenMock,
} from 'mocks/users/authMock'

// Services
const { url: createUrl } = createAuthService(
  loginMock.email,
  loginMock.password,
  loginMock.rememberMe,
)
const { url: refreshUrl } = refreshAuthService()
const { url: exchangeUrl } = exchangeAuthService(authMock.organisation)
const { url: listPermUrl } = listAuthPermissionsService()

// Stubs
const createStub = successHandlerCreateStub(createUrl, tokenMock)
const refreshStub = successHandlerCreateStub(refreshUrl, tokenMock)
const exchangeStub = successHandlerCreateStub(exchangeUrl, tokenMock)
const listStub = successHandlerListStub(listPermUrl, authPermissionsMock)

export default [createStub, refreshStub, exchangeStub, listStub]
