import React, { FunctionComponent } from 'react'
import { SWRConfig } from 'swr'
import { Alert, AlertContainer } from "electivegroup-component-library";
import Routes from "routes/Routes";

const App: FunctionComponent = () => {
  return (
    <SWRConfig
      value={{
        shouldRetryOnError: false,
        revalidateOnFocus: false,
      }}>
      <AlertContainer>
        <Alert/>
        <Routes />
      </AlertContainer>
    </SWRConfig>
  )
}

export default App
