import useSWR, { cache, mutate as existingMutate, useSWRInfinite } from 'swr'
import isObject from 'lodash/isObject'
import startsWith from 'lodash/startsWith'
import isNull from 'lodash/isNull'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import first from 'lodash/first'
import isArray from 'lodash/isArray'
import pull from 'lodash/pull'
import cloneDeep from 'lodash/cloneDeep'
import forOwn from 'lodash/forOwn'
import { createFilterString, QueryOptionsType } from 'utils/base'
import { useState } from 'react'
const jwt_decode = require('jwt-decode')
const windowConfig: any = window

export enum HostConst {
  NONE = 0,
  ACL = windowConfig.CONNECT_ACL_API,
  ASSESSMENTS = windowConfig.CONNECT_ASSESSMENT_API,
  CANDIDATES = windowConfig.CONNECT_CANDIDATE_API,
  INTEGRATIONS = windowConfig.CONNET_INTEGRATIONS_API,
  DIALOG = windowConfig.CONNECT_DIALOG_API,
  RASA = windowConfig.CONNECT_RASA_API,
  CONFIG = windowConfig.CONNECT_CONFIG_API,
  LABELS = windowConfig.CONNECT_LABELS_API,
  REPORTS = windowConfig.CONNECT_REPORTS_API,
}

export enum MethodConst {
  GET = 'GET',
  HEAD = 'HEAD',
  POST = 'POST',
  PATCH = 'PATCH',
  PUT = 'PUT',
  DELETE = 'DELETE',
  CSV = 'CSV',
  AUDIO = 'AUDIO',
}

export type RequestOptionsType = {
  noValidate?: boolean
  shouldFetch?: boolean
  token?: string
  noToken?: boolean
  refreshInterval?: number
}

export const usePreFetch = (service: any) => {
  const { useCacheFetch } = useApiRequest()

  const [fetchItems, setFetchItems] = useState(false)

  useCacheFetch(service, {
    shouldFetch: fetchItems,
    noValidate: true,
  })

  return () => setFetchItems(true)
}

export const useInfinitePreFetch = (service: any, shouldFetch = true) => {
  const { useInfiniteFetch } = useApiRequest()

  const [fetchItems, setFetchItems] = useState(false)

  useInfiniteFetch(() => service, {
    shouldFetch: fetchItems,
    noValidate: true,
  })

  return () => setFetchItems(true)
}

export const useApiRequest = () => {
  // get auth jwt token
  const getToken = (options?: RequestOptionsType) => {
    if (options && options.noToken) {
      return ''
    } else {
      return options && options.token
        ? options.token
        : ''
    }
  }

  const getOptions = (options?: RequestOptionsType) => {
    const token = getToken(options)
    const noValidate = options?.noValidate
    const refreshInterval = options ? options?.refreshInterval : undefined
    let shouldFetch =
      options && !isUndefined(options.shouldFetch) ? options.shouldFetch : true

    let username
    let organisation
    try {
      const decodedToken = jwt_decode(token)
      organisation = decodedToken.organisation
      username = decodedToken.username
    } catch (e) {
    }

    return {
      token,
      noValidate,
      shouldFetch,
      organisation,
      username,
      refreshInterval,
    }
  }

  const postFetch = (service: any, options?: RequestOptionsType) => {
    const { url, method, postData } = service
    return apiCall(url, method, getToken(options), postData)
  }

  const useCacheFetch = (service: any, options?: RequestOptionsType) => {
    const { url, method, postData } = service

    let {
      token,
      noValidate,
      shouldFetch,
      organisation,
      username,
      refreshInterval,
    } = getOptions(options)

    const key = [url, username, organisation]

    const shouldValidate = !(noValidate && cache.has(key))

    if (!shouldValidate) {
      shouldFetch = false
    }

    const { data, error, mutate } = useSWR(
      shouldFetch ? [url, username, organisation] : null,
      () => apiCall(url, method, token, postData),
      { refreshInterval }
    )

    if (!shouldValidate) {
      return { data: cache.get(key), mutate: () => existingMutate(key) }
    }

    return {
      data,
      error,
      mutate,
    }
  }

  const useInfiniteFetch = (service: any, options?: RequestOptionsType) => {
    const { method, postData } = service()

    let { token, organisation, username, shouldFetch, noValidate } = getOptions(
      options
    )

    const returnKey = (index: number) => {
      const { url } = service(index)
      return [url, username, organisation]
    }

    const { data, error, mutate, size, setSize } = useSWRInfinite(
      index => (shouldFetch ? returnKey(index) : null),
      url => apiCall(url, method, token, postData),
      { revalidateAll: !noValidate }
    )

    const initial = first(data)
    const resultsTotal =
      initial && initial.resultsTotal ? initial.resultsTotal : 0

    return {
      data,
      error,
      mutate,
      resultsTotal,
      size,
      setSize,
    }
  }

  return {
    useInfiniteFetch,
    useCacheFetch,
    postFetch,
  }
}

export const apiData = (
  method: MethodConst,
  path: string,
  host: HostConst,
  queryOptions?: QueryOptionsType,
  postData = {}
) => {
  // if host defined then create URL, if not then full url was provided by path
  const url = host ? createUrl(path, host, queryOptions) : path

  return {
    url,
    method,
    postData,
  }
}

export const createUrl = (
  path: string,
  host: HostConst,
  queryOptions?: QueryOptionsType
) => {
  return `${host}${path}?${
    queryOptions ? createFilterString(queryOptions) : ''
  }`
}

export const apiCall = async (
  url: string,
  method: MethodConst,
  token?: string,
  postData?: object
) => {
  try {
    const headerMethod = [MethodConst.AUDIO, MethodConst.CSV].includes(method)
      ? MethodConst.GET
      : method

    const headerAccept =
      method == MethodConst.CSV ? 'text/csv' : 'application/json'

    const options: any = {
      method: headerMethod,
      headers: {
        Accept: headerAccept,
        'Content-Type': 'application/json',
      },
    }

    // add post data
    if (postData && !isEmpty(postData)) {
      options.body = JSON.stringify(pruneEmpty(postData))
    }

    if (token) {
      options.headers['Authorization'] = `Bearer ${token}`
    }

    const response = await fetch(url, options)

    let results
    let error
    let json

    if (method === MethodConst.AUDIO) {
      json = await response.blob()
    } else if (method === MethodConst.CSV) {
      json = await response.text()
    } else if (response.status !== 204 && method !== MethodConst.HEAD) {
      json = await response.json()
    }

    if (startsWith(response.status.toString(), '2')) {
      results = json
    } else {
      error = json
    }

    const resultsTotal: any = response.headers.get('X-Results-Total')

    return {
      results,
      error,
      resultsTotal: parseInt(resultsTotal),
      status: response.status,
    }
  } catch (error) {
    return { results: null, error: null, resultsTotal: null, status: null }
  }
}

export const pruneEmpty = (data: object) => {
  return (function prune(current: any) {
    forOwn(current, function(value, key) {
      if (
        isUndefined(value) ||
        isNull(value) ||
        (isObject(value) && !isArray(value) && isEmpty(prune(value)))
      ) {
        delete current[key]
      }
    })
    // remove any leftover undefined values from the delete
    // operation on an array
    if (isArray(current)) pull(current, undefined)

    return current
  })(cloneDeep(data))
}

export const removeWhiteSpace = (text: string) => {
  return text.replace(/\s/g, '')
}

export const formatPhone = (text: string) => {
  const firstLetter = text.toString().charAt(0)
  if (firstLetter !== '+' && text !== '') {
    text = `+${text}`
  }

  return removeWhiteSpace(text)
}

export const removeEmpty = (object: any) => {
  Object.entries(object).forEach(item => {
    if (item[1] === '') {
      delete object[item[0]]
    }
  })
  return object
}
