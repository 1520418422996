import { lazyWithPreload } from 'utils/base'

export const StartPage = lazyWithPreload(() =>
  import('pages/StartPage/StartPage')
)
export const StartedPage = lazyWithPreload(() =>
  import('pages/StartedPage/StartedPage')
)
export const ProfilePage = lazyWithPreload(() =>
  import('pages/ProfilePage/ProfilePage')
)
export const PicturePage = lazyWithPreload(() =>
  import('pages/PicturePage/PicturePage')
)
export const ResumePage = lazyWithPreload(() =>
  import('pages/ResumePage/ResumePage')
)
export const RightToWorkPage = lazyWithPreload(() =>
  import('pages/RightToWorkPage/RightToWorkPage')
)
export const PassportPage = lazyWithPreload(() =>
  import('pages/PassportPage/PassportPage')
)
export const WelcomePage = lazyWithPreload(() =>
  import('pages/WelcomePage/WelcomePage')
)
export const HomePage = lazyWithPreload(() =>
  import('pages/HomePage/HomePage')
)
export const WorkPage = lazyWithPreload(() =>
  import('pages/WorkPage/WorkPage')
)
export const ChatPage = lazyWithPreload(() =>
  import('pages/ChatPage/ChatPage')
)
export const AssistantPage = lazyWithPreload(() =>
  import('pages/AssistantPage/AssistantPage')
)
export const UnsubscribePage = lazyWithPreload(() =>
  import('pages/UnsubscribePage/UnsubscribePage')
)
export const UnsubscribeSuccessPage = lazyWithPreload(() =>
  import('pages/UnsubscribeSuccessPage/UnsubscribeSuccessPage')
)
export const RegisterSuccessPage = lazyWithPreload(() =>
  import('pages/RegisterSuccessPage/RegisterSuccessPage')
)
export const RegisterPage = lazyWithPreload(() =>
  import('pages/RegisterPage/RegisterPage')
)
export const LoginPage = lazyWithPreload(() =>
  import('pages/LoginPage/LoginPage')
)
export const ProfileHomePage = lazyWithPreload(() =>
  import('pages/ProfileHomePage/ProfileHomePage')
)
export const ProfileEditPage = lazyWithPreload(() =>
  import('pages/ProfileEditPage/ProfileEditPage')
)
export const ProfileContactsPage = lazyWithPreload(() =>
  import('pages/ProfileContactsPage/ProfileContactsPage')
)