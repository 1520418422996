import styled from 'styled-components'
import { theme } from 'styles/theme'

type MatrixLoaderContainerProps = {
  gridColumn?: string
}

export const MatrixLoaderContainer = styled.div<MatrixLoaderContainerProps>`
  display: grid;
  grid-template-columns: auto 22px;
  grid-column-gap: 16px;
  padding-bottom: 8px;
  ${props =>
    props.gridColumn &&
    `
      grid-template-columns: ${props.gridColumn};
    `};
`

export const LoadingBarStyled = styled.div`
  background-color: ${theme.grayGeyser};
  width: 100%;
  height: 16px;
`

export const LoadingBoxStyled = styled.div`
  background-color: ${theme.grayGeyser};
  height: 16px;
  margin-right: 2px;
`

// CHANNEL CONTAINER
export const ChannelLoaderContainer = styled.div`
  padding: 16px;
`

export const QuestionStyled = styled.div`
  background-color: ${theme.grayGeyser};
  width: 40%;
  height: 16px;
`

type PlaceholderStyledProps = {
  height?: number
  width?: number
  margin?: string
  borderRadius?: string
  backgroudColor?: string
}

export const PlaceholderStyled = styled.div<PlaceholderStyledProps>`
  background-color: ${theme.grayGeyser};
  width: 30%;
  height: 8px;
  margin-bottom: 4px;
  ${props =>
    props.height &&
    `
        height: ${props.height}px;
    `};
  ${props =>
    props.backgroudColor &&
    `
        background-color: ${props.backgroudColor};
    `};
  ${props =>
    props.width &&
    `
        width: ${props.width}%;
    `};
  ${props =>
    props.margin &&
    `
        margin: ${props.margin};
    `};
  ${props =>
    props.borderRadius &&
    `
        border-radius: ${props.borderRadius};
    `};
`

export const QuestionContainerStyled = styled.div`
  padding: 8px 0;
`


export const RememberLoaderStyled = styled.div`
  margin: 0 16px;
`


// Quickstart
export const QuickstartStyled = styled.div`
  padding: 32px 24px;
  @media screen and (min-width: 750px) {
    padding: 8px 24px;
  }
`

export const FormLoaderStyled = styled.div`
  border: 1px solid ${theme.grayGeyser};
  border-radius: 4px;
  padding: 16px 8px;
  height: 600px;
  width: 380px;
`

export const QuickstartContentLoader = styled.div`
  padding: 24px 0;
  @media screen and (min-width: 750px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
  }
`

export const JobDetailsLoaderStyled = styled.div`
  padding-bottom: 16px;
  @media screen and (min-width: 750px) {
    padding-bottom: 0;
  }
`