import React, { FC, Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { EmployeeTemplate } from 'templates'
import { TitleWrapperStyled } from 'pages/LoginPage/styles'
import { Card, Font } from 'electivegroup-component-library'
import BusinessIcon from '@material-ui/icons/Business'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Todo from './Todo'

const HomePage: FC = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Home | RECii</title>
      </Helmet>
      <EmployeeTemplate>
        <Card theme="primary" fullWidth>
          <Font variant="h4" component="h1" gutterBottom align="center">
            Welcome to the Team!
          </Font>
          <TitleWrapperStyled>
            <BusinessIcon />
            <Font variant="h4" component="h1" gutterBottom align="center">
              Our Company
            </Font>
          </TitleWrapperStyled>
          <TitleWrapperStyled>
            <HelpOutlineIcon />
            <Font variant="h4" component="h1" gutterBottom align="center">
              Have a question?
            </Font>
          </TitleWrapperStyled>
        </Card>
        <Card theme="primary" fullWidth>
          <Font variant="h3" component="h1" gutterBottom align="center">
            To Do
          </Font>
          <Todo label="Upload your resume" route="resume" />
          <Todo label="Upload your right to work" route="right-to-work" />
          <Todo label="Upload your passport" route="passport" />
        </Card>
      </EmployeeTemplate>
    </Fragment>
  )
}

export default HomePage
