import React from 'react'
import {
  LoadingBarStyled,
  MatrixLoaderContainer,
  LoadingBoxStyled,
} from './styles'

const CheckBoxLoader = () => (
  <MatrixLoaderContainer gridColumn="22px auto">
    <LoadingBoxStyled />
    <LoadingBarStyled />
  </MatrixLoaderContainer>
)

export default CheckBoxLoader
