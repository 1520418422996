import { HostConst, MethodConst, apiData } from './base'

export const createAuthService = (
  username: string,
  password: string,
  organisation?: string | null
) => {
  let data: any = {
    username,
    password,
  }

  if (organisation) {
    data.organisation = organisation
  }
  return apiData(MethodConst.POST, 'authenticate', HostConst.ACL, {}, data)
}

export const refreshAuthService = () => {
  return apiData(MethodConst.POST, 'token/refresh', HostConst.ACL)
}

export const exchangeAuthService = (organisation: string) => {
  const data = {
    organisation,
  }

  return apiData(MethodConst.POST, 'token/exchange', HostConst.ACL, {}, data)
}

export const listAuthPermissionsService = () => {
  return apiData(MethodConst.GET, `token/permissions`, HostConst.ACL)
}
