import React, { Component } from 'react'

class ErrorBoundary extends Component {
  state = { hasError: false, error: null }

  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error,
    }
  }
  componentDidCatch(e, errorInfo) {}

  render() {
    if (this.state.hasError) {
      return this.props.fallback ? (
        this.props.fallback
      ) : (
        <h1>Something went wrong.</h1>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
