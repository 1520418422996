import React, { FC } from 'react'
import {
  PlaceholderStyled,
  RememberLoaderStyled,
  QuickstartStyled,
  FormLoaderStyled,
  QuickstartContentLoader,
  JobDetailsLoaderStyled,
} from './styles'
import { CheckBoxLoader } from 'common/loaders'
import { Avatar } from 'electivegroup-component-library'
import { theme } from 'styles/theme'

const QuickStartLoader: FC = () => {
  return (
    <QuickstartStyled>
      <div style={{ display: 'flex' }}>
        <Avatar color={theme.grayGeyser}> </Avatar>
        <PlaceholderStyled height={16} width={5} margin="8px 16px 0" />
      </div>
      <QuickstartContentLoader>
        <JobDetailsLoaderStyled>
          <PlaceholderStyled height={16} width={40} />
          <PlaceholderStyled height={8} width={40} />
          <PlaceholderStyled height={8} width={40} />
          <PlaceholderStyled height={2} width={100} margin="16px 0" />
          <PlaceholderStyled height={120} width={100} />
        </JobDetailsLoaderStyled>
        <FormLoaderStyled>
          <PlaceholderStyled height={42} width={40} margin="16px auto 32px" />
          <PlaceholderStyled height={24} width={60} margin="0 auto 32px" />
          <PlaceholderStyled height={60} width={90} margin="0 auto 32px" />
          <PlaceholderStyled height={60} width={90} margin="0 auto 32px" />
          <PlaceholderStyled height={60} width={90} margin="0 auto 32px" />
          <RememberLoaderStyled>
            <CheckBoxLoader />
            <PlaceholderStyled height={16} width={90} margin="16px auto 32px" />
          </RememberLoaderStyled>
          <PlaceholderStyled height={32} width={90} margin="0 auto 32px" />
        </FormLoaderStyled>
      </QuickstartContentLoader>
    </QuickstartStyled>
  )
}

export default QuickStartLoader
