import React, { Suspense, FC } from 'react'
import {
  StartPage,
  StartedPage,
  RegisterSuccessPage,
  UnsubscribePage,
  UnsubscribeSuccessPage,
  RegisterPage,
  LoginPage,
} from 'pages'
import { QuickStartLoader } from 'common/loaders'
import { Redirect, Route, Switch } from 'react-router-dom'
import PublicRoute from 'routes/PublicRoute'
import AccountRoutes from 'routes/AccountRoutes'

// TODO: page not found (token route)
const Routes: FC = () => {
  const auth = localStorage.getItem('auth')
  return (
    <Switch>
      <Suspense fallback={<QuickStartLoader />}>
        {/*<Route path={`/`} exact render={() => <Redirect to={'/login'} />} />*/}
        <PublicRoute pathRoute={'/login'} component={LoginPage} />
        <PublicRoute pathRoute={'/register/:token'} component={RegisterPage} />
        <PublicRoute
          pathRoute={'/quickstart/:token'}
          component={RegisterPage}
        />
        <PublicRoute pathRoute={'/start/:token'} component={StartPage} />
        <PublicRoute pathRoute={'/started'} component={StartedPage} />
        <PublicRoute
          pathRoute={'/quickstart/success/:token'}
          component={RegisterSuccessPage}
        />
        <PublicRoute
          pathRoute={'/unsubscribe/:candidateId-:token'}
          component={UnsubscribePage}
        />
        <PublicRoute
          pathRoute={'/unsubscribe/success/:token'}
          component={UnsubscribeSuccessPage}
        />
        <Route
          path={'/account'}
          render={(props: any) =>
            auth ? <AccountRoutes {...props} /> : <Redirect to={'/login'} />
          }
        />
      </Suspense>
    </Switch>
  )
}

export default Routes
