export const contactIdMock = 'kmo9Et1IxqCi'

export const contactMock = {
  id: contactIdMock,
  title: 'Mr',
  firstName: 'Chris',
  lastName: 'Example',
  email: 'chris@recii.io',
  phone: '+447920463222',
}
export const contactsMock: any = [
  contactMock,
  {
    id: contactIdMock,
    title: 'Mrs',
    firstName: 'Christine',
    lastName: 'Example',
    email: 'christine@recii.io',
    phone: '+447920463222',
  },
]
