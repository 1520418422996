import { lazyWithPreload } from 'utils/base'

export const FooterTemplate = lazyWithPreload(() => import('templates/base/FooterTemplate'))
export const MainTemplate = lazyWithPreload(() => import('./MainTemplate'))
export const AccountTemplate = lazyWithPreload(
  () => import('./AccountTemplate')
)
export const InfoTemplate = lazyWithPreload(() => import('./InfoTemplate'))
export const StartPageTemplate = lazyWithPreload(
  () => import('./StartPageTemplate')
)
export const ErrorPageTemplate = lazyWithPreload(
  () => import('./ErrorPageTemplate')
)
export const FormTemplate = lazyWithPreload(
  () => import('./FormTemplate')
)
export const ProgressTemplate = lazyWithPreload(
  () => import('./ProgressTemplate')
)
export const EmployeeTemplate = lazyWithPreload(
  () => import('./EmployeeTemplate')
)
export const ProfileTemplate = lazyWithPreload(
  () => import('./ProfileTemplate/ProfileTemplate')
)