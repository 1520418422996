export const candidateIdMock = 'kmo9Et1IxqCi'

export const addressMock: any = {
  addressLine1: '12',
  addressLine2: 'Example street',
  city: 'Leeds',
  postcode: 'LS7 3DF',
  country: 'UK',
  county: 'Berks',
}

export const jobMock: any = {
  title: 'Web dev',
  location: 'Leeds',
  willRelocate: true,
  expectedSalary: '20000',
  salaryCurrency: 'GBP',
  salaryUnit: 'annual',
  notice: '2',
  noticeUnit: 'weeks',
  jobType: 'temporary',
  company: 'Recii',
  summary: 'AI it',
  additionalInformation: 'more info',
}

export const privacyMock: any = {
  acceptedAt: null,
  browser: null,
  contactMethods: [],
  device: null,
  id: 'qcPfdbZBElBA',
  ip: null,
  preferredContactTime: 1080,
  token: 'M420h7zTkpxh5Jlbr0OG4SfxPWGLnI2DUiTs5HB2dz',
  whatsAppOptedInAt: null,
}

export const candidateMock: any = {
  id: candidateIdMock,
  address: addressMock,
  privacy: privacyMock,
  alternativePhoneNumber: '+447920463222',
  bullhornId: null,
  candidateLabels: {},
  cvText: null,
  deletedAt: null,
  dob: {
    date: '2020-08-27 11:27:09.000000',
    timezone_type: 3,
    timezone: 'UTC',
  },
  email: 'chris@recii.io',
  firstName: 'Chris',
  gender: 'male',
  job: jobMock,
  labels: [],
  lastName: 'Example',
  organisation: 'xgMFcydeNLBg',
  phone: '+447920463222',
  primaryLanguage: 'English',
  profileScore: null,
  secondaryLanguage: 'Chinese',
  status: 'available',
  tertiaryLanguage: null,
  title: 'Mr',
  vendorId: null,
  vendorSource: null,
  vendorStatus: null,
}

export const candidatesMock: any = [candidateMock]


