import React, { FunctionComponent } from 'react'
import { Route } from 'react-router-dom'

type Props = {
  component: any
  pathRoute: any
}

const PublicRoute: FunctionComponent<Props> = ({
  component: Component,
  pathRoute,
}) => {
  return (
    <Route
      exact
      path={pathRoute}
      render={(props: any) => <Component {...props} />}
    />
  )
}

export default PublicRoute
