import {
  readCandidateService,
  updateCandidateService,
} from 'services/candidateServices'
import { successHandlerReadStub, successHandlerUpdateStub } from 'mocks/base'
import { candidateIdMock, candidateMock } from 'mocks/candidates/candidateMock'

// Services
const { url: readUrl } = readCandidateService(candidateIdMock)
const { url: updateUrl } = updateCandidateService(
  candidateIdMock,
  candidateMock
)

// Stubs
const readStub = successHandlerReadStub(readUrl, candidateMock)
const updateStub = successHandlerUpdateStub(updateUrl, candidateMock)

export default [readStub, updateStub]
