import React, { StrictMode } from 'react'
import './styles/styles.scss'
import { render } from 'react-dom'
import { ErrorBoundary } from 'utils'
import { ErrorPageTemplate } from 'templates'
import App from './App'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

const target = document.querySelector('#root')
if (module && module.hot) {
  module.hot.accept()
}

const { worker } = require('mocks/browser')
worker.start()

const history = createBrowserHistory()
render(
  <StrictMode>
    <ErrorBoundary fallback={<ErrorPageTemplate />}>
      <Router history={history}>
        <App />
      </Router>
    </ErrorBoundary>
  </StrictMode>,
  target
)
