import React, { Suspense, FC } from 'react'
import {
  StartedPage,
  ProfilePage,
  PicturePage,
  ResumePage,
  RightToWorkPage,
  PassportPage,
  WelcomePage,
  WorkPage,
  ChatPage,
  AssistantPage,
  ProfileHomePage,
  ProfileEditPage,
  ProfileContactsPage
} from 'pages'
import { QuickStartLoader } from 'common/loaders'
import { Route } from 'react-router-dom'
import HomePage from "pages/HomePage/HomePage";

const AccountRoutes: FC = () => {
  return (
    <Suspense fallback={<QuickStartLoader />}>
      <Route
        path={`/account/started`}
        render={(props: any) => <StartedPage {...props} />}
      />
      <Route
        path={`/account/profile-details`}
        render={(props: any) => <ProfilePage {...props} />}
      />
      <Route
        path={`/account/picture`}
        render={(props: any) => <PicturePage {...props} />}
      />
      <Route
        path={`/account/resume`}
        render={(props: any) => <ResumePage {...props} />}
      />
      <Route
        path={`/account/right-to-work`}
        render={(props: any) => <RightToWorkPage {...props} />}
      />
      <Route
        path={`/account/passport`}
        render={(props: any) => <PassportPage {...props} />}
      />
      <Route
        path={`/account/welcome`}
        render={(props: any) => <WelcomePage {...props} />}
      />
      <Route
        path={`/account/home`}
        render={(props: any) => <HomePage {...props} />}
      />
      <Route
        path={`/account/work`}
        render={(props: any) => <WorkPage {...props} />}
      />
      <Route
        path={`/account/chat`}
        render={(props: any) => <ChatPage {...props} />}
      />
      <Route
        path={`/account/assistant`}
        render={(props: any) => <AssistantPage {...props} />}
      />
      <Route
        path={`/account/profile`}
        exact
        render={(props: any) => <ProfileHomePage {...props} />}
      />
      <Route
        path={`/account/profile/edit`}
        render={(props: any) => <ProfileEditPage {...props} />}
      />
      <Route
        path={`/account/profile/contacts`}
        render={(props: any) => <ProfileContactsPage {...props} />}
      />
    </Suspense>
  )
}

export default AccountRoutes
