import { successHandlerReadStub } from 'mocks/base'
import { candidateIdMock } from 'mocks/candidates/candidateMock'
import { candidateJobMock } from 'mocks/candidates/jobMock'
import { readCandidateJobService } from "services/candidateJobServices";

// Services
const { url: readUrl } = readCandidateJobService(candidateIdMock)

// Stubs
const readStub = successHandlerReadStub(readUrl, candidateJobMock)

export default [readStub]
