export const userIdMock: any = '34ii3ngvg'

export const userMock: any = {
  id: userIdMock,
  username: 'user',
  firstName: 'name2',
  lastName: 'last2',
  phone: '01789',
  createdAt: {
    date: '2019-04-24 15:21:30.000000',
    timezone_type: 3,
    timezone: 'UTC',
  },
  updatedAt: null,
  deletedAt: null,
  primaryEmail: null,
  isActive: true,
}

export const usersMock: any = [
  userMock,
  {
    id: '2',
    username: '1@email.com',
    firstName: 'name1',
    lastName: 'last1',
    phone: '02789',
    createdAt: {
      date: '2019-04-24 13:26:37.000000',
      timezone_type: 3,
      timezone: 'UTC',
    },
    updatedAt: null,
    deletedAt: null,
    primaryEmail: null,
    isActive: false,
  },
]
