import { HostConst, MethodConst, apiData } from 'services/base'
import { CandidateType } from "types/candidateTypes";

export const readCandidateService = (id: string) => {
  return apiData(MethodConst.GET, `candidates/${id}`, HostConst.CANDIDATES)
}

export const updateCandidateService = (
  id: string,
  candidate: CandidateType
) => {
  return apiData(
    MethodConst.PATCH,
    `candidates/${id}`,
    HostConst.CANDIDATES,
    {},
    candidate
  )
}

export const unsubscribeCandidateService = (
  id: string,
  unsubscribeToken: string
) => {
  return apiData(
    MethodConst.POST,
    `candidates/${id}/unsubscribe/${unsubscribeToken}`,
    HostConst.CANDIDATES,
    {}
  )
}

export const verifyCandidateService = (id: string, token: string) => {
  return apiData(
    MethodConst.PATCH,
    `candidates/verify/${id}-${token}`,
    HostConst.CANDIDATES,
    {}
  )
}
